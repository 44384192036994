import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const getCoverUrl = (slug, projectsCovers) => {
  const coverUrlDev = `projects\\${slug}`
  const coverUrl = `projects/${slug}`
  const coverUrlObject = projectsCovers.find(({ node }) => {
    return node.relativeDirectory === coverUrl || node.relativeDirectory === coverUrlDev
  })
  return coverUrlObject ? coverUrlObject.node.publicURL : null;
}

const IndexPage = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        query ProjectsQuery {
          allProjectsJson {
            edges {
              node {
                name
                slug
                cover
                client
                year
              }
            }
          }
          allFile(
            sort: {
              order: ASC,
              fields: [name]
            }
            filter: {
            absolutePath: {
              regex: "/(cover)/"
            }
          }) {
            edges {
              node {
                id
                publicURL
                name
                relativeDirectory
              }
            }
          }
        }
      `}
      render={data => (
        <ul className="unstyled-list project-list">
          {data.allProjectsJson.edges.map(({node}) => {
            const {
              name,
              slug,
              client,
              year,
            } = node;
            return (
              <li key={slug} className="project-list-item" style={{backgroundImage: `url(${getCoverUrl(slug, data.allFile.edges)})`}}>
                <Link
                  to={`projects/${slug}`}
                  getProps={() => {
                    return {
                      className: 'project-list-item-link'
                    }
                  }}
                >
                  <div className="project-list-item-image" />
                  <div className="project-list-item-hover">
                    <div className="project-list-item-informations">
                        <h2 className="project-title">
                          {name}
                        </h2>
                        <p className="project-sub-title">
                          <span>{client}</span>
                          <span>-</span>
                          <span>{year}</span>
                        </p>                 
                      </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    />
  </Layout>
)

export default IndexPage